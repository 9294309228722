<style lang="scss" scoped>
article {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $lightGrayOutline;
}

article:last-of-type {
  border-bottom: 3px double $lightGrayOutline;
}

.course-title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1.5;
  margin-right: 2.5rem;
}

.spare-explanation {
  font-size: 0.8rem;
  font-style: italic;
}

.title {
  font-size: 1.6rem;
}

.trash {
  position: absolute;
  right: 0;
  top: 0;
  color: $lightGray;
  cursor: pointer;
  .icon {
    margin-right: -5px;
  }
}

.icon {
  margin-top: 0.25rem;
}

.course {
  position: relative;
}

.line-status {
  margin-bottom: 0.2rem;

  .id {
    margin-right: 0.5rem;
  }
}

.client {
  margin: 0.5rem 0;
}

.button {
  margin-top: 1rem;
  padding: 1.5rem;
  width: 100%;
  &.is-small {
    border-radius: 9px;
    width: auto;
    padding: 0.5rem 1rem;
  }
}

.b-checkbox {
  align-items: flex-start;
}

.prices {
  margin-bottom: 2rem;

  .total-group {
    margin-bottom: 0.5rem;
  }
}

.price {
  font-weight: bold;
}

.lightGray {
  color: $lightGray;
}

.only-spares {
  margin-top: 1rem;
}

.dicountlabel {
  display: none;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.2rem;

  .priceBig {
    font-weight: 600;
    font-size: 1.6rem;
    margin: 0.5rem 0;
  }

  div {
    display: flex;
    align-items: baseline;
    font-size: 0.8rem;

    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
</style>

<template>
  <div class="card">
    <div class="card-content">
      <h2 class="title">{{ $t('summary') }}</h2>

      <article v-for="item in items" :key="item.cartItemId" class="course">
        <div v-if="item.type !== 'discount'">
          <h4 class="course-title">{{ item.name }}</h4>

          <div class="trash" v-if="!item.registrationToLessons">
            <b-icon icon="trash-can-outline" @click.native="removeCartItem(item.cartItemId)" />
          </div>

          <div class="line-status">
            <div class="lightGray id">{{ item.courseCode }}</div>
            <RegistrationStatus :registration="item" :fromCart="true" />
          </div>

          <div class="client">
            {{ $t('client.client') }}:
            <span :class="!item.clientName && 'lightGray'">
              {{ item.clientName || $t('cart.notselected') }}
            </span>
          </div>

          <div class="row" v-for="subitem in item.items" :key="subitem.id">
            <div>{{ subitem.name }}</div>
            <div class="price" v-if="!item.spare">{{ $n(subitem.amount / 100, 'currency') }}</div>
            <div v-if="item.spare">{{ $n(subitem.spareamount / 100, 'currency') }}&nbsp;*</div>
          </div>
        </div>
        <div v-if="item.type === 'discount'">
          <h4 class="course-title">{{ item.name }}</h4>
          <div class="row" v-for="subitem in item.items" :key="subitem.id">
            <div>{{ subitem.name }}</div>
            <div class="price" v-if="!item.spare">{{ $n(subitem.amount / 100, 'currency') }}</div>
          </div>
        </div>
      </article>

      <section
        class="prices"
        v-if="
          purchase &&
          purchase.productstotal &&
          (purchase.productstotal.amount > 0 || purchase.productstotal.spareamount > 0)
        "
      >
        <div class="row total" v-if="purchase.productstotal.amount > 0">
          <div>{{ $t('cart.paymenttotal') }}</div>
          <div class="price">{{ $n(purchase.productstotal.amount / 100, 'currency') }}</div>
        </div>

        <div class="row othertotal" v-if="hasAnySpare">
          <div>{{ $t('cart.sparetotal') }}</div>
          <div>{{ $n(purchase.productstotal.spareamount / 100, 'currency') }}&nbsp;*</div>
        </div>

        <div class="row othertotal" v-if="purchase.productstotal.culturevoucher">
          <div>{{ $t('cart.summary.culturevouchertotal') }}</div>
          <div>{{ $n(purchase.productstotal.culturevoucher / 100, 'currency') }}</div>
        </div>

        <div class="row othertotal" v-if="purchase.productstotal.sportsvoucher">
          <div>{{ $t('cart.summary.sportsvouchertotal') }}</div>
          <div>{{ $n(purchase.productstotal.sportsvoucher / 100, 'currency') }}</div>
        </div>

        <div class="row" v-if="purchase.productstotal.canpaynow">
          <div>
            {{ $t('cart.canpaynow') }}
          </div>
          <div class="priceBig">
            {{ $n(purchase.productstotal.canpaynow / 100, 'currency') }}
          </div>
        </div>

        <div class="row" v-if="purchase.productstotal.mustpaynow">
          <div>
            {{ $t('cart.mustpaynow') }}
          </div>
          <div class="priceBig">
            {{ $n(purchase.productstotal.mustpaynow / 100, 'currency') }}
          </div>
        </div>

        <div
          class="spare-explanation"
          :class="purchase.productstotal.amount <= 0 && 'only-spares'"
          v-if="hasAnySpare"
        >
          {{ $t('cart.spareExplanation') }}
        </div>
        <b-collapse :open="false" position="is-top" aria-id="contentIdForA11y4">
          <template #trigger="props">
            <a aria-controls="contentIdForA11y4" :aria-expanded="props.open">
              <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
              {{ $t('cart.discountcode') }}
            </a>
          </template>
          <label>
            <span class="dicountlabel">{{ $t('cart.code') }}</span>
            <b-input lazy v-model.trim="discountcode" :id="discountcode" />
          </label>
          <div class="buttons is-right">
            <b-button
              size="is-small"
              type="is-primary "
              :aria-label="$t('cart.discountcodecheck')"
              @click="checkDiscount"
            >
              {{ $t('cart.discountcodecheck') }}
            </b-button>
          </div>
        </b-collapse>
      </section>

      <b-field>
        <b-checkbox v-model="termsAccepted">
          <i18n path="cart.terms">
            <router-link :to="{ name: 'help' }" target="_blank">
              <span v-html="$t('cart.instructions')"></span>
            </router-link>
            <a v-if="brand.privacystatementurl" :href="brand.privacystatementurl" target="_blank">
              <span v-html="$t('cart.privacystatement')"></span>
            </a>
          </i18n>
        </b-checkbox>
      </b-field>
      <b-field>
        <b-button
          type="is-primary"
          :aria-label="$t('cart.send')"
          :loading="postRegIsLoading"
          @click="sendRegistration"
        >
          {{ $t('cart.send') }}
        </b-button>
      </b-field>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, PropType, ref } from '@vue/composition-api';
import { ToastProgrammatic as Toast } from 'buefy';
import { includes, some } from 'lodash/fp';
import { useGetBrand } from '../../hooks/useBrandApi';

import {
  HellewiCartItem,
  HellewiCourseStatus,
  PurchaseProductNumber,
  RegistrationPriceNumber
} from '../../api';

import { Client } from '../../views/Cart.vue';
import RegistrationStatus from '../../components/registration/RegistrationStatus.vue';

interface CartSummaryItem extends PurchaseProductNumber {
  clientName?: string;
  spare?: boolean;
  sparecounter?: number;
  courseCode?: string;
  registrationToLessons: boolean;
}

export default defineComponent({
  components: { RegistrationStatus },
  props: {
    cartItems: {
      type: Array as PropType<HellewiCartItem[]>,
      required: true
    },
    clients: {
      type: Array as PropType<Client[]>,
      required: true
    },
    purchase: {
      type: Object as PropType<RegistrationPriceNumber>,
      required: true
    },
    postRegIsLoading: {
      type: Boolean,
      required: true
    }
  },
  setup: (props, ctx) => {
    const { response: brand, execute: getBrand } = useGetBrand();

    const termsAccepted = ref<boolean>(false);

    const discountcode = ref<string>('');

    const items = computed<CartSummaryItem[]>(() =>
      props.purchase.products.map((product) => {
        const client = props.clients.find((c) =>
          c.selectedCartItemIds.includes(product.cartItemId || -1)
        );

        const cartItem = props.cartItems.find((c) => c.id === product.cartItemId);

        const clientName =
          client && (client.fields.firstname || client.fields.lastname)
            ? `${client.fields.firstname || ''} ${client.fields.lastname || ''}`
            : undefined;

        const spare = cartItem?.spare;
        const sparecounter = cartItem?.sparecounter;
        const courseCode = cartItem?.course?.code;
        // TODO: jos PurchaseProductItemType:ssä olisi lesson, niin tätä ei tarvisi tehdä
        // näin vaikeasti
        const registrationToLessons = includes(
          HellewiCourseStatus.RegistrationToLessons,
          cartItem?.course.statuses
        );

        return { ...product, clientName, spare, sparecounter, courseCode, registrationToLessons };
      })
    );

    const paymentlater = computed<number>(
      () =>
        props.purchase.productstotal.amount -
        (props.purchase.productstotal.mustpaynow || 0) -
        (props.purchase.productstotal.canpaynow || 0)
    );

    const hasAnySpare = computed<boolean>(() => some((i) => Boolean(i.spare), items.value));

    const sendRegistration = () => {
      if (!termsAccepted.value) {
        Toast.open({
          message: ctx.root.$t('cart.termserror').toString(),
          type: 'is-danger',
          position: 'is-bottom'
        });
        return;
      }

      ctx.emit('send');
    };

    const removeCartItem = (id: number) => {
      ctx.emit('remove-cart-item', id);
    };

    const checkDiscount = () => {
      ctx.emit('check-discount-code', discountcode.value);
    };

    onBeforeMount(() => {
      getBrand();
    });

    return {
      brand,
      discountcode,
      checkDiscount,
      items,
      sendRegistration,
      termsAccepted,
      removeCartItem,
      paymentlater,
      hasAnySpare
    };
  }
});
</script>
