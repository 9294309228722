<style lang="scss" scoped>
#app .course {
  background: #f5f5f5;
  border-radius: 13px;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  .questions {
    width: 100%;
    margin-top: 1rem;

    .field {
      margin-bottom: 1rem;

      .has-addons {
        flex-direction: column;
      }
    }
    .course-question-dropdown {
      max-width: 300px;
    }
  }

  .mobile-checkbox {
    display: none;
  }

  .desktop-checkbox,
  .trash-desktop {
    margin-top: 0.3rem;
    align-self: flex-start;
    .icon {
      margin-right: 0;
    }
  }

  .course-selectors {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .course-selector {
      margin-top: 1rem;
      width: 100%;
    }
  }

  .course-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -0.5rem;
    width: 100%;

    h3 {
      font-weight: 600;
      margin-bottom: 0;
      font-size: 1.3rem;
    }

    .courseDetails {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0.5rem;

      a {
        color: $darkGrey;
      }
    }

    .courseCode {
      color: #474747;
      margin-right: 0.5rem;
    }
  }
}

.trash {
  display: none;
  cursor: pointer;
}

.selectedCourse {
  background: #f2f9ff !important;
}

@media (max-width: 600px) {
  .course {
    display: block;

    .courseCode {
      display: block;
    }

    .trash-desktop {
      display: none;
    }

    .desktop-checkbox {
      display: none;
    }
    .mobile-checkbox {
      display: block !important;
    }
  }
  .course-mobile-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .courseDetails {
      width: 100%;
    }

    .trash {
      display: block;
    }
  }
}

.user-selection-question {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.user-selection-container {
  width: 100%;
}
.user-selection-choice {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-content: stretch;

  &.b-radio.radio:not(.button),
  &.b-checkbox.checkbox:not(.button) {
    margin-right: 0;
  }

  @media (min-width: 650px) {
    width: initial;
    justify-content: initial;
    &.b-radio.radio:not(.button),
    &.b-checkbox.checkbox:not(.button) {
      margin-right: 0.5rem;
    }
  }
}
.user-selection-title {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
  font-weight: 700;
}
.user-selection-content {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;

  .installment-name {
    padding-right: 1rem;
  }
}
.course-title {
  margin-right: 0.75rem;

  @media (min-width: $tablet) {
    display: inline-block;
  }
}
</style>
<style lang="scss">
.user-selection-container > .field-body > .field {
  display: grid;
  justify-content: stretch;

  @media (min-width: 650px), (min-width: 1226px) and (max-width: 1407px) {
    grid-template-columns: 1fr 1fr;
    justify-content: left;
  }
  @media (min-width: 950px) and (max-width: 1225px), (min-width: 1408px) {
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: left;
  }
}
</style>

<template>
  <article class="course" :class="{ selectedCourse: isSelected }">
    <div v-if="showCheckbox" @click.self="toggleItem" class="desktop-checkbox">
      <b-checkbox
        :value="isSelected"
        @input="toggleItem"
        :aria-label="getCourseSelectorLabel(isSelected, item.course.name || '')"
      />
    </div>
    <div class="course-content">
      <div class="course-mobile-wrapper">
        <div v-if="showCheckbox" @click.self="toggleItem" class="mobile-checkbox">
          <b-checkbox
            :value="isSelected"
            @input="toggleItem"
            :aria-label="getCourseSelectorLabel(isSelected, item.course.name || '')"
          />
        </div>
        <div class="courseDetails">
          <div>
            <router-link :to="{ name: 'course', params: { id: item.course.id } }">
              <h3 class="course-title">
                {{ item.course.name }}
              </h3>
            </router-link>
            <span v-if="ageLimitString" class="agelimit">({{ ageLimitString }})</span>
          </div>
          <div>
            <span class="courseCode">{{ item.course.code }}</span>
            <div v-if="item.lesson">
              {{ $d(new Date(item.lesson.begins), 'weekdayLong') | capitalize }}
              <template v-if="item.course.privatelessons">
                {{ item.lesson.begins | timeRange(item.lesson.ends) }}
              </template>
              <template v-else>
                {{ item.lesson.begins | dateTimeRange(item.lesson.ends) }}
              </template>
            </div>
            <RegistrationStatus :registration="item" :fromCart="true" />
          </div>
        </div>
        <div class="trash">
          <b-icon icon="trash-can-outline" @click.native="removeCartItem(item.id)" />
        </div>
      </div>
      <div class="course-selectors">
        <!-- Price selector -->
        <div class="course-selector" v-if="item.course.prices && item.course.prices.length > 1">
          <span class="user-selection-question">{{ $t('cart.selectPrice') }}:</span>
          <b-field class="user-selection-container">
            <b-radio
              class="user-selection-choice"
              v-for="price in item.course.prices"
              :key="price.id"
              v-model="selectedPrice"
              :native-value="price"
            >
              <span class="user-selection-title">
                {{ price.name ? formatLabel(price.name) : formatAmount(price.amount) }}
              </span>
              <span class="user-selection-content" v-if="price.name">
                {{ formatAmount(price.amount) }}
              </span>
            </b-radio>
          </b-field>
        </div>

        <!-- Installment selector -->
        <div
          v-if="selectedPrice && installmentGroups && installmentGroups.length > 1"
          class="course-selector"
        >
          <span class="user-selection-question">{{ $t('cart.selectInstallment') }}:</span>
          <b-field class="user-selection-container">
            <b-radio
              v-for="(installmentgroup, i) in installmentGroups"
              v-model="selectedInstallmentgroup"
              class="user-selection-choice"
              :key="i"
              :native-value="installmentgroup"
            >
              <span class="user-selection-title">
                {{
                  i === 0
                    ? formatLabel(installmentgroup.name) || $t('cart.installments.default')
                    : formatLabel(installmentgroup.name) || $t('cart.installments.multipleDefault')
                }}
              </span>
              <span class="user-selection-content">
                <template v-for="installment in installmentgroup.installments">
                  <span :key="`${installment.id}-1`" class="installment-name">
                    {{ formatLabel(installment.name) || $t('cart.installments.default') }}
                  </span>
                  <span :key="`${installment.id}-2`" class="installment-amount">
                    {{ formatAmount(installment.amount) }}
                  </span>
                </template>
              </span>
            </b-radio>
          </b-field>
        </div>

        <!-- CourseProducts selector -->
        <div
          v-if="item.course.courseProducts && item.course.courseProducts.length > 0"
          class="course-selector"
        >
          <span class="user-selection-question">{{ $t('cart.selectCourseProducts') }}:</span>
          <b-field class="user-selection-container">
            <b-checkbox
              v-for="(product, i) in sortedCourseProducts"
              v-model="selectedCourseProducts"
              class="user-selection-choice"
              :key="i"
              :native-value="product"
            >
              <span class="user-selection-title">
                {{ formatLabel(product.name) || $t('cart.installments.default') }}
              </span>
              <span class="user-selection-content">
                {{ formatAmount(product.price * 100) }}
              </span>
            </b-checkbox>
          </b-field>
        </div>
      </div>

      <section
        class="questions"
        v-if="questionFields && isSelected"
        :class="{ infoNoPrice: !item.course.prices || item.course.prices.length === 0 }"
      >
        <ValidationObserver tag="form" ref="observer">
          <div v-for="(field, i) in questionFields" :key="i.toString()">
            <ValidationProvider :vid="i.toString()" :rules="{ required: field.required }" slim>
              <div slot-scope="{ errors }">
                <b-field
                  :type="{ 'is-danger': errors[0] }"
                  :message="$t(errors[0])"
                  :label="field.title"
                  :label-for="field.title + item.course.id"
                  :custom-class="field.required ? 'required' : ''"
                >
                  <b-input
                    v-if="questionFieldTypes[field.id].questionType === 'freetext'"
                    v-model="questionAnswers[field.id]"
                    type="textarea"
                    maxlength="2000"
                    :has-counter="false"
                    lazy
                    :id="field.title + item.course.id"
                  />

                  <b-select
                    class="course-question-dropdown"
                    v-if="questionFieldTypes[field.id].questionType === 'dropdown'"
                    v-model="questionAnswers[field.id]"
                    :placeholder="$t('select')"
                    :id="field.title + item.course.id"
                  >
                    <option v-for="option in field.oneOf" :key="option.const" :value="option.const">
                      {{ option.title }}
                    </option>
                  </b-select>

                  <div v-if="questionFieldTypes[field.id].questionType === 'radioselect'">
                    <b-field v-for="option in field.oneOf" :key="option.const">
                      <b-radio
                        v-model="questionAnswers[field.id]"
                        :name="field.title + item.course.id"
                        :native-value="option.const"
                      >
                        {{ option.title }}
                      </b-radio>
                    </b-field>
                  </div>

                  <p
                    class="help is-danger"
                    v-if="errors.length === 0 && clientAjvErrors.length > 0"
                  >
                    {{ getFieldError(field.instancePathId) }}
                  </p>
                </b-field>
              </div>
            </ValidationProvider>
          </div>
        </ValidationObserver>
      </section>
    </div>
    <div class="trash-desktop">
      <b-icon icon="trash-can-outline" @click.native="removeCartItem(item.id)" />
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, watch, ref } from '@vue/composition-api';
import { fromPairs } from 'lodash/fp';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import {
  HellewiCartItem,
  HellewiCoursePrice,
  HellewiCoursePriceInstallment,
  HellewiCourseProduct
} from '../../api';

import { getAgeLimitTranslation } from '../../utils/agelimit-translation';

import { translate } from '../../utils/misc-utils';

import { AjvError, Client, ClientCourseDetails } from '../../views/Cart.vue';
import { CourseDetailsChangePayload } from './ClientCard.vue';

import RegistrationStatus from '../registration/RegistrationStatus.vue';

enum QuestionType {
  RadioSelect = 'radioselect',
  FreeText = 'freetext',
  Dropdown = 'dropdown'
}

interface QuestionFields {
  id: string;
  title: string;
  type: 'number' | 'string';
  default: string | undefined;
  oneOf: Array<{ title: string; const: string }> | undefined;
  questionType: QuestionType;
  required?: boolean;
}

const defaultQuestionAnswers = (qfs: QuestionFields[]) =>
  fromPairs(qfs.map((field) => [field.id, field.default]));

export default defineComponent({
  components: {
    RegistrationStatus,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    item: {
      type: Object as PropType<HellewiCartItem>,
      required: true
    },
    itemIndex: {
      type: Number,
      required: false
    },
    schema: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    },
    coursedetails: {
      type: Object as PropType<ClientCourseDetails | undefined>,
      required: false
    },
    showCheckbox: {
      type: Boolean,
      required: false
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    ajvErrors: {
      type: Array as PropType<AjvError[]>,
      required: false
    }
  },
  setup(props, ctx) {
    const observer = ref<InstanceType<typeof ValidationObserver> | null>(null);
    const isSelected = ref<boolean>(props.selected);
    const selectedPrice = ref<HellewiCoursePrice | undefined>(
      props.coursedetails?.price || undefined
    );
    const selectedInstallmentgroup = ref<HellewiCoursePriceInstallment | undefined>(
      props.coursedetails?.installmentgroup || undefined
    );

    // Sort course products by name
    const sortedCourseProducts = computed(() => {
      if (props.item.course.courseProducts) {
        return [...props.item.course.courseProducts].sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [];
      }
    });

    let selectedCourseProducts = ref<HellewiCourseProduct[]>([]);

    const courseInSchema = computed<any>( // eslint-disable-line
      () => props.schema.$defs[`course-${props.item.course?.id}`]
    );

    const ageLimitString = computed<string | undefined>(() => {
      const { ageLimits } = props.item.course;
      if (!ageLimits || (!ageLimits.minAge && !ageLimits.maxAge)) {
        return undefined;
      }
      const [translationKey, params] = getAgeLimitTranslation(ageLimits);
      return translate(ctx, translationKey, params);
    });

    const clientAjvErrors = computed<AjvError[]>(
      () => props.ajvErrors?.filter((err) => props.client.id === err.clientid) || []
    );

    const questionFields = computed<QuestionFields[]>(
      () =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        courseInSchema.value.properties.questions?.items?.map((item: any, i: number) =>
          item.properties.optionid
            ? {
                id: String(item.properties.id.const),
                instancePathId: i.toString(),
                title: item.title,
                type: item.properties.optionid.type,
                questionType: item.questionType,
                default: item.properties.optionid.default,
                oneOf: item.properties.optionid.oneOf,
                required: true // Dropdowns don't need the * indicator
              }
            : {
                id: String(item.properties.id.const),
                instancePathId: i.toString(),
                title: item.title,
                type: item.properties.value.type,
                questionType: item.questionType,
                required: item.required?.length === 2
              }
        ) || []
    );

    const questionAnswers = ref<Record<string, string | undefined>>(
      defaultQuestionAnswers(questionFields.value)
    );

    const installmentGroups = computed<HellewiCoursePriceInstallment[]>(() => {
      if (!selectedPrice.value || !props.schema) {
        return [];
      }

      const priceInSchema = courseInSchema.value.properties.price.oneOf.find(
        (p: any) => p.properties.id.const === selectedPrice.value?.id // eslint-disable-line
      );

      if (priceInSchema?.required?.includes('installments')) {
        return selectedPrice.value.installmentgroups || [];
      } else {
        return [
          { name: translate(ctx, 'cart.installments.default'), installments: [] },
          ...(selectedPrice.value.installmentgroups || [])
        ];
      }
    });

    const questionFieldTypes = computed<
      Record<string, { questionType: QuestionType; valueType: string }>
    >(() =>
      fromPairs(
        questionFields.value.map((field) => [
          field.id,
          { valueType: field.type, questionType: field.questionType }
        ])
      )
    );

    const formatLabel = (name?: string) => {
      return name;
    };

    const removeCartItem = (id: number) => {
      ctx.emit('remove-cart-item', id);
    };

    const getFieldError = (instancePathId: string) => {
      const error = clientAjvErrors.value.find((e) => {
        const split = e.instancePath.split('/');
        const errorFieldId = split[split.length - 1];

        const checkIfCorrectItemOrTrue = (splititem: string) => {
          if (props.itemIndex) {
            return !isNaN(parseInt(splititem, 10)) && splititem === String(props.itemIndex);
          }
          return true;
        };
        return errorFieldId === instancePathId && checkIfCorrectItemOrTrue(split[split.length - 4]);
      });

      if (!error) {
        return '';
      }

      return translate(ctx, `validation.${error.keyword}`);
    };

    const toggleItem = () => {
      // Disable toggling when there's only 1 item & client
      if (!props.showCheckbox) {
        return;
      }

      isSelected.value = !isSelected.value;
      ctx.emit('toggle-item', props.item.id);
    };

    const getCourseSelectorLabel = (isSelected: boolean, courseName: string) => {
      return isSelected
        ? translate(ctx, 'cart.courseDeselect', { courseName })
        : translate(ctx, 'cart.courseSelect', { courseName });
    };

    const formatAmount = (amount?: number) =>
      amount || amount === 0 ? ctx.root.$n(amount / 100, 'currency') : '';

    const emitChanges = (type: 'prices' | 'details') => {
      const emitted: CourseDetailsChangePayload = {
        courseAndLessonId: `${props.item.itemid.id}-${props.item.itemid.lessonid}`,
        details: {
          price: selectedPrice.value,
          installmentgroup: selectedInstallmentgroup.value,
          courseProducts: selectedCourseProducts.value,
          questions: Object.keys(questionAnswers.value)
            .map((id) => Number(id))
            .sort((a, b) => a - b)
            .map((id) => ({
              id,
              ...(questionFieldTypes.value[id].valueType === 'string'
                ? { value: questionAnswers.value[id] }
                : { optionid: Number(questionAnswers.value[id]) })
            }))
        }
      };
      ctx.emit(`change-${type}`, emitted);
    };
    // emit initial question values
    emitChanges('details');

    watch(selectedInstallmentgroup, () => {
      emitChanges('prices');
    });

    watch(questionFields, (qfs) => {
      questionAnswers.value = defaultQuestionAnswers(qfs);
    });

    watch(
      questionAnswers,
      () => {
        emitChanges('details');
      },
      { deep: true }
    );

    watch(installmentGroups, () => {
      selectedInstallmentgroup.value = installmentGroups.value[0];
    });

    watch(selectedCourseProducts, () => {
      emitChanges('prices');
    });

    return {
      clientAjvErrors,
      courseInSchema,
      emitChanges,
      formatLabel,
      formatAmount,
      getFieldError,
      installmentGroups,
      isSelected,
      observer,
      questionAnswers,
      questionFieldTypes,
      questionFields,
      removeCartItem,
      selectedInstallmentgroup,
      selectedPrice,
      toggleItem,
      ageLimitString,
      selectedCourseProducts,
      sortedCourseProducts,
      getCourseSelectorLabel
    };
  }
});
</script>
